var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entity-booking-rules-form"},[_c('h3',[_vm._v(_vm._s(_vm.$t('bookingRulesForm.title')))]),_c('form-container',[_c('repeater-input',{staticClass:"col-12",attrs:{"label":_vm.$t("labels.bookingSlots"),"structure":[
					{
						key: 'start',
						type: 'number',
						label: _vm.$t('labels.start'),
						class:'col-4'
					},
					{
						key: 'end',
						type: 'number',
						label: _vm.$t('labels.end'),
						class:'col-4'
					},
					{
						key: 'priceMultiplier',
						type: 'number',
						label: _vm.$t('labels.priceMultiplier'),
						class:'col-4'
					}
				],"errors":_vm.bookingslotErrors,"disabled":_vm.disabled},on:{"focusout":_vm.updateAfterCertainChange},model:{value:(_vm.localValues.BOOKING_SLOTS),callback:function ($$v) {_vm.$set(_vm.localValues, "BOOKING_SLOTS", $$v)},expression:"localValues.BOOKING_SLOTS"}})],1),_c('form-container',[_c('repeater-input',{staticClass:"col-12",attrs:{"label":_vm.$t("labels.lateCancelPenalties"),"structure":[
					{
						key: 'hoursBefore',
						type: 'number',
						label: _vm.$t('labels.hoursBefore'),
						class:'col-6'
					},
					{
						key: 'penaltyPercent',
						type: 'number',
						label: _vm.$t('labels.penaltyPercent'),
						class:'col-6'
					}
				],"disabled":_vm.disabled,"errors":_vm.lateCancelError},on:{"focusout":_vm.updateAfterCertainChange},model:{value:(_vm.localValues.BOOKING_LATE_CANCEL_PENALTIES),callback:function ($$v) {_vm.$set(_vm.localValues, "BOOKING_LATE_CANCEL_PENALTIES", $$v)},expression:"localValues.BOOKING_LATE_CANCEL_PENALTIES"}})],1),_c('form-container',[_c('field-input',{attrs:{"label":_vm.$t("labels.pricePrHour"),"disabled":_vm.disabled},on:{"focusout":_vm.updateAfterCertainChange},model:{value:(_vm.localValues.BOOKING_PRICE_PR_HOUR),callback:function ($$v) {_vm.$set(_vm.localValues, "BOOKING_PRICE_PR_HOUR", $$v)},expression:"localValues.BOOKING_PRICE_PR_HOUR"}}),_c('field-input',{attrs:{"label":_vm.$t("labels.minimumHoursBefore"),"disabled":_vm.disabled},on:{"focusout":_vm.updateAfterCertainChange},model:{value:(_vm.localValues.BOOKING_MINIMUM_HOURS_BEFORE),callback:function ($$v) {_vm.$set(_vm.localValues, "BOOKING_MINIMUM_HOURS_BEFORE", $$v)},expression:"localValues.BOOKING_MINIMUM_HOURS_BEFORE"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }