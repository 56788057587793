import {mapState,mapGetters} from "vuex";
import permissions from "@/permissions";
export default {
	computed: {
		...mapState('concrete', {
			userPermissions: 'userPermissions'
		}),
		...mapGetters('concrete', {
			hasGlobalSuperAdmin: 'hasGlobalSuperAdmin'
		}),
	},
	methods: {
		/**
		 * Does the logged in user have a specific permission on a given entity or globally?
		 * onEntity = undefined means it will only look through global permissions
		 *
		 * @param permission
		 * @param onEntity
		 * @returns {boolean}
		 */
		isGranted(permission,onEntity){
			// Super admin may always do everything
			if(this.hasGlobalSuperAdmin)
				return true;
			
			if(Array.isArray(permission))
			{
				for(let i = 0; i < permission.length; i++){
					if(this.isGranted(permission[i], onEntity) === false)
						return false;
				}
				return true;
			}
			else
			{
				//If user have global permission
				if(this.userPermissions.global.indexOf(permission) !== -1)
					return true;
				
				//If an entity was given as a permission dimension
				if(typeof onEntity !== 'undefined')
				{
					//If the entity is a string it is assumed to be an IRI
					if(typeof onEntity === 'string')
					{
						return this.checkPermissionByIRI(permission,onEntity)
					}
					//If the entity is a hydra-style API entity
					else if(typeof onEntity['@type'] !== 'undefined')
					{
						return this.checkPermissionOnHydraStyleEntity(permission,onEntity)
					}
					//Other types of validation than a hydra entity / IRI...
				}
				
				return false;
			}
		},
		checkPermissionOnHydraStyleEntity(permission, entity){
			if(entity['@type'] === 'User')
			{
				//Check if logged in user has the required permission via the requested users' districts
				for(let i = 0; i < entity['districts'].length; i++)
				{
					if(this.checkPermissionByIRI(permission,entity['districts'][i]))
						return true;
				}
				
				//Check if logged in user has the required permission via the requested users' companies
				for(let i = 0; i < entity['companies'].length; i++)
				{
					if(this.checkPermissionByIRI(permission,entity['companies'][i]))
						return true;
				}
			}
			//If it's a district or company, check on the specific entity
			else if(entity['@type'] === 'District' || entity['@type'] === 'Company')
			{
				return (this.checkPermissionByIRI(permission,entity['@id']));
			}
			//Other entity types
			return false;
		},
		checkPermissionByIRI(permission, entityString){
			return typeof this.userPermissions.entities[entityString] !== 'undefined' &&
				(
					this.userPermissions.entities[entityString].indexOf(permissions.META.SUPER_ADMIN) !== -1 ||
					this.userPermissions.entities[entityString].indexOf(permission) !== -1
				)
		}
	}
}
