<template>
	<select class="custom-select mr-3" ref="select" v-model="localValue" @change="updateValue()" :multiple="multiple">
		<option v-if="name !== ''" value="">{{name}}</option>
		<option v-for="(option,optionIndex) in options" :key="optionIndex" :value="option.value" >{{option.text}}</option>
	</select>
</template>

<script>
    export default {
        name: "SelectInput",
	    data(){
            return {
            	localValue: ''
            }
	    },
        methods: {
	        getSelectValues(select) {
			    let result = [];
			    const options = select && select.options;
			    let opt;
			
			    for (let i=0, iLen=options.length; i<iLen; i++) {
				    opt = options[i];
				
				    if (opt.selected) {
					    result.push(opt.value || opt.text);
				    }
			    }
			    return result;
		    },
            updateValue()
			{
				if(this.multiple)
					this.$emit('input', this.getSelectValues(this.$refs.select));
				else
					this.$emit('input', this.$refs.select.value);
			},
        },
	    created(){
            this.localValue = this.value;
	    },
	    watch: {
            value(to){
            	this.localValue = to;
            }
	    },
	    props: {
		    value: {
			    type: String|Array
		    },
		    options: {
			    type: Array,
			    default: null
		    },
		    name: {
			    type: String,
			    default: ""
		    },
		    multiple: {
			    type: Boolean,
			    default: false
		    }
	    },
    }
</script>

<style lang="scss" scoped>

</style>
