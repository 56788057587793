<template>
	<div class="entity-currency-rules-form">
		<h3>{{$t('currencyRulesForm.title')}}</h3>
		<form-container>
			<field-input :label='$t("labels.currency")' v-model="localValues.CURRENCY" :disabled='disabled' @focusout="updateAfterCertainChange" />
			<field-select :label='$t("labels.currencyPosition")' v-model="localValues.CURRENCY_POSITION" :options="[{value:'left',text:$t('currencyRulesForm.currencyPositionLeft')},{value:'right',text:$t('currencyRulesForm.currencyPositionRight')}]" :disabled='disabled' />
			<field-input :label='$t("labels.thousandsSeparator")' v-model="localValues.CURRENCY_THOUSANDS_SEPARATOR" classes="col-6 col-md-4" :disabled='disabled' @focusout="updateAfterCertainChange" />
			<field-input :label='$t("labels.decimalSeparator")' v-model="localValues.CURRENCY_DECIMAL_SEPARATOR" classes="col-6 col-md-4" :disabled='disabled' @focusout="updateAfterCertainChange" />
			<field-input :label='$t("labels.decimalCount")' v-model="localValues.CURRENCY_DECIMAL_COUNT" classes="col-6 col-md-4" :disabled='disabled' @focusout="updateAfterCertainChange" />
		</form-container>
	</div>
</template>

<script>
	import FieldInput from "@/components/inputs/FieldInput";
	import {mapGetters} from "vuex";
	import FormContainer from "@/components/container/FormContainer";
	import rules from "@/rules";
	import {normalizeRuleValue} from "@/helperFunctions";
	import FieldSelect from "@/components/inputs/FieldSelect";
	
	export default {
		name: "EntityCurrencyRulesForm",
		components: {FieldSelect, FormContainer, FieldInput},
		data(){
			return {
				localValues: {
					CURRENCY: '',
					CURRENCY_POSITION: '',
					CURRENCY_THOUSANDS_SEPARATOR: '',
					CURRENCY_DECIMAL_SEPARATOR: '',
					CURRENCY_DECIMAL_COUNT: ''
				}
			}
		},
		computed: {
			...mapGetters('concrete',{
				groupedRules: 'groupedRules',
				normalizedDefaultRuleValues: 'normalizedDefaultRuleValues'
			}),
		},
		methods: {
			updateLocalValues()
			{
				this.groupedRules.currency.forEach((rule) => {
					const normalized = rule.replace(':','_');
					this.localValues[normalized] = (typeof this.value[rule] !== 'undefined') ? this.value[rule] : this.defaultValues[rule];
				});
			},
			updateAfterCertainChange(){
				let newValue = {};
				this.groupedRules.currency.forEach((rule) => {
					const normalized = rule.replace(':','_');
					const normalizedValue = normalizeRuleValue(rule,this.localValues[normalized]);
					if(normalizedValue !== '')
					{
						newValue[rule] = this.localValues[normalized];
					}
				});
				this.$emit('input',newValue);
			}
		},
		mounted()
		{
			this.updateLocalValues();
		},
		watch:{
			value:{
				deep:true,
				handler: function(){
					this.updateLocalValues();
				}
			},
			localValues: {
				deep: true,
				handler: function(to){
					let newValue = {};
					this.groupedRules.currency.forEach((rule) => {
						const normalized = rule.replace(':','_');
						newValue[rule] = this.localValues[normalized];
					});
					this.$emit('input',newValue);
				}
			}
		},
		props: {
			value: {
				type: Object,
				default: () => {
					return {}
				}
			},
			defaultValues: {
				type: Object,
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style scoped>

</style>
