<template>
	<div class="entity-booking-rules-form">
		<h3>{{$t('bookingRulesForm.title')}}</h3>
		<form-container>
			<repeater-input
				class="col-12"
				:label='$t("labels.bookingSlots")'
				v-model="localValues.BOOKING_SLOTS" :structure="[
					{
						key: 'start',
						type: 'number',
						label: $t('labels.start'),
						class:'col-4'
					},
					{
						key: 'end',
						type: 'number',
						label: $t('labels.end'),
						class:'col-4'
					},
					{
						key: 'priceMultiplier',
						type: 'number',
						label: $t('labels.priceMultiplier'),
						class:'col-4'
					}
				]"
				:errors="bookingslotErrors"
				:disabled='disabled'
				@focusout="updateAfterCertainChange"
			 />
		</form-container>
		<form-container>
			<repeater-input
				class="col-12"
                :label='$t("labels.lateCancelPenalties")'
                v-model="localValues.BOOKING_LATE_CANCEL_PENALTIES"
                :structure="[
					{
						key: 'hoursBefore',
						type: 'number',
						label: $t('labels.hoursBefore'),
						class:'col-6'
					},
					{
						key: 'penaltyPercent',
						type: 'number',
						label: $t('labels.penaltyPercent'),
						class:'col-6'
					}
				]"
				:disabled='disabled'
				:errors="lateCancelError"
				@focusout="updateAfterCertainChange"
			/>
		</form-container>
		<form-container>
			<field-input :label='$t("labels.pricePrHour")' v-model="localValues.BOOKING_PRICE_PR_HOUR" :disabled='disabled' @focusout="updateAfterCertainChange" />
			<field-input :label='$t("labels.minimumHoursBefore")' v-model="localValues.BOOKING_MINIMUM_HOURS_BEFORE" :disabled='disabled' @focusout="updateAfterCertainChange" />
		</form-container>
	</div>
</template>

<script>
	import FieldInput from "@/components/inputs/FieldInput";
	import {mapGetters} from "vuex";
	import FormContainer from "@/components/container/FormContainer";
	import rules from "@/rules";
	import {normalizeRuleValue} from "@/helperFunctions";
	import RepeaterInput from "@/components/inputs/RepeaterInput";
	
	export default {
		name: "EntityBookingRulesForm",
		components: {RepeaterInput, FormContainer, FieldInput},
		data(){
			return {
				localValues: {
					BOOKING_SLOTS: [],
					BOOKING_PRICE_PR_HOUR: '',
					BOOKING_MINIMUM_HOURS_BEFORE: '',
					BOOKING_LATE_CANCEL_PENALTIES: [],
				}
			}
		},
		computed: {
			...mapGetters('concrete',{
				groupedRules: 'groupedRules',
				normalizedDefaultRuleValues: 'normalizedDefaultRuleValues'
			}),
			bookingslotErrors(){
				return typeof this.errors.bookingslot !== 'undefined' ? this.errors.bookingslot : {} ;
			},
			lateCancelError(){
				return typeof this.errors.lateCancel !== 'undefined' ? this.errors.lateCancel : {} ;
			}
		},
		methods: {
			updateLocalValues()
			{
				this.groupedRules.booking.forEach((rule) => {
					const normalized = rule.replace(':','_');
					this.localValues[normalized] = (typeof this.value[rule] !== 'undefined') ? this.value[rule] : rules[rule].default;
				});
			},
			updateAfterCertainChange(){
				let newValue = {};
				this.groupedRules.booking.forEach((rule) => {
					const normalized = rule.replace(':','_');
					const normalizedValue = normalizeRuleValue(rule,this.localValues[normalized]);
					if(normalizedValue !== '')
					{
						newValue[rule] = this.localValues[normalized];
					}
				});
				this.$emit('input',newValue);
			}
		},
		mounted()
		{
			this.updateLocalValues();
		},
		watch:{
			value:{
				deep:true,
				handler: function(){
					this.updateLocalValues();
				}
			},
			localValues: {
				deep: true,
				handler: function(to){
					let newValue = {};
					this.groupedRules.booking.forEach((rule) => {
						const normalized = rule.replace(':','_');
						newValue[rule] = this.localValues[normalized];
					});
					this.$emit('input',newValue);
				}
			}
		},
		props: {
			value: {
				type: Object,
				default: () => {
					return {}
				}
			},
			defaultValues: {
				type: Object,
				required: true
			},
			errors: {
				type: Object,
				default: () => {
					return {}
				}
			},
			disabled: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style scoped>

</style>
