<template>
	<div class="repeater-input">
		<div v-if="typeof label !== 'undefined'" class="repeater-label">{{label}}</div>
		<div v-for="(singleValue,index) in localValue" :key="index" class="row">
			<field-input v-for="part in structure" v-model="localValue[index][part.key]" :key="part.key" :type="part.type" :label="part.label" :classes="part.class" @input="triggerChange" :error="(typeof errors[part.key+'-'+index] !== 'undefined') ? errors[part.key+'-'+index] : ''" :disabled='disabled' @focusout="fireFocusout" />
			<div v-if='!disabled' class="delete" @click="deleteRow(index)"></div>
			<div v-if='!disabled' class="add-new-row" @click="addNewRow(index,true)"></div>
		</div>
		<div v-if='!disabled && localValue.length === 0' class="row">
			<div class="add-new-row" @click="addNewRow(0,true)"></div>
		</div>
		
	</div>
</template>

<script>
	
	import FieldInput from "@/components/inputs/FieldInput";
	export default {
		name: "RepeaterInput",
		components: {FieldInput},
		data(){
			return {
				localValue: []
			}
		},
		methods: {
			updateLocalValues(){
				let values = [];
				this.value.forEach((single) => {
					let newValue = {};
					this.structure.forEach((part) => {
						newValue[part.key] = (typeof single[part.key] !== 'undefined') ? single[part.key] : null;
					});
					values.push(newValue);
				});
				this.localValue = values;
			},
			deleteRow(index){
				this.localValue.splice(index,1);
				this.$emit('input',this.localValue);
			},
			addNewRow(index, after)
			{
				let newValue = {};
				this.structure.forEach((part) => {
					newValue[part.key] = null;
				});
				index = after === true ? index + 1 : index ;
				this.localValue.splice(index, 0, newValue);
				this.$emit('input',this.localValue);
			},
			triggerChange(){
				this.forceValueTypes();
				this.$emit('input',this.localValue);
			},
			forceValueTypes(){
				this.localValue.forEach((single,index) => {
					this.structure.forEach((part) => {
						this.localValue[index][part.key] = part.type === 'number' ? Number(single[part.key]) :  single[part.key];
					});
				});
			},
			fireFocusout(event){
				this.$emit('focusout',event)
			}
		},
		mounted()
		{
			this.updateLocalValues()
		},
		watch: {
			value: {
				deep: true,
				handler: function(){
					this.updateLocalValues();
				}
			},
		},
		props: {
			value: {
				type: Array,
				required: true
			},
			structure: {
				type: Array,
				required: true
			},
			errors: {
				type: Object,
				default: {}
			},
			label: String,
			disabled: {
				type: Boolean,
				default: false
			}
		}
	};
</script>

<style lang="scss" scoped>
	.repeater-input{
		width: 100%;
		.repeater-label{
			font-size: 1rem;
			color: $lightGray;
			pointer-events: none;
		}
		.row{
			position: relative;
			margin: 0 -24px;
			padding: 0 10px;
			&:nth-child(odd){
				background: $lightgrey;
			}
			&:hover,&:last-of-type{
				.add-new-row{
					display: block;
				}
			}
			.delete{
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				display: inline-block;
				width: 18px;
				height: 18px;
				background: $black;
				float: right;
				border-radius: 3px;
				margin: 1px 5px 0 0;
				cursor: pointer;
				&:before, &:after{
					display: block;
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 8px;
					height: 2px;
					background: $white;
					transform: translate(-50%,-50%) rotate(45deg);
				}
				&:after{
					transform: translate(-50%,-50%) rotate(135deg);
				}
			}
			.add-new-row{
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%,50%);
				display: none;
				width: 18px;
				height: 18px;
				background: $black;
				float: right;
				border-radius: 3px;
				margin: 1px 5px 0 0;
				cursor: pointer;
				z-index: 10;
				&:before, &:after{
					display: block;
					content: "";
					position: absolute;
					top: 50%;
					left: 50%;
					width: 8px;
					height: 2px;
					background: $white;
					transform: translate(-50%,-50%);
				}
				&:after{
					transform: translate(-50%,-50%) rotate(90deg);
				}
			}
		}
	}
</style>
