<template>
	<div class="regular-input" :class="classes">
		<label v-if="typeof label !== 'undefined'" :for="id">{{label}}</label>
		<input class="form-control" :id="id" :type="type" :placeholder="placeholder" ref="input" :value="value" @input="updateValue()" />
		<div v-if="hasError" class="error">{{error}}</div>
	</div>
</template>

<script>
	let uuid = 0;
	export default {
		name: "RegularInput",
		data () {
			return {
				id: null
			}
		},
		computed: {
			hasError(){
				return typeof this.error !== 'undefined' && this.error !== '';
			},
			classes(){
				return [
					this.hasError ? 'has-error' : ''
				]
			},
		},
		methods: {
			updateValue()
			{
				this.$emit('input', this.$refs.input.value)
			},
		},
		props:{
			type: {
				default: 'text',
				type: String
			},
			placeholder: String,
			label: String,
			value: String,
			error: String
		},
		created() {
			this.id = 'c-input-'+uuid.toString();
			uuid += 1;
		}
	};
</script>

<style lang="scss" scoped>
	.regular-input{
		display: block;
		width: 100%;
		margin-bottom: 1.5rem;
		&.has-error{
			input{
				border: 1px solid red;
				&::placeholder{
					color: red;
				}
			}
		}
		input{
			display: block;
			::placeholder{
				color: $lightGray;
			}
		}
		.error{
			color: red;
			padding-left: 15px;
		}
	}
</style>
