<template />

<script>
	export default {
		name: "MapPolygon",
		data(){
			return {
				polygon: {},
				drawingManager: {}
			}
		},
		computed: {
			compiledOptions(){
				const options = this.options;
				if(this.editable)
					options.editable = true;
				return options;
			}
		},
		methods: {
			initPathEvents(){
				if(this.paths.length > 0)
				{
					this.google.maps.event.addListener(this.polygon.getPath(), 'set_at', (index, obj) =>
					{
						this.$emit('pathChanged', this.polygon.getPath());
					});
					this.google.maps.event.addListener(this.polygon.getPath(), 'insert_at', (index, obj) =>
					{
						this.$emit('pathChanged', this.polygon.getPath());
					});
					this.google.maps.event.addListener(this.polygon.getPath(), "remove_at", (index, obj) =>
					{
						this.$emit('pathChanged', this.polygon.getPath());
					});
				}
			},
			initAllEvents(){
				this.map.addListener('click', (mapsMouseEvent) => {
					this.$emit('mapClick',mapsMouseEvent);
				});
				this.initPathEvents();
			}
		},
		beforeDestroy(){
			this.polygon.setMap(null);
		},
		mounted() {
			this.polygon = new this.google.maps.Polygon({
				paths: this.paths,
				...this.compiledOptions
			});
			this.polygon.setMap(this.map);

			//@todo check if editing
			if(typeof this.onClickRoute !== 'undefined' && this.onClickRoute !== null && this.onClickRoute !== {} ){
				this.polygon.addListener('click', () => {
					//var win = window.open(this.onClickUrl, '_blank');
					//win.focus(); 
					let routeData = this.$router.resolve(this.onClickRoute);
					window.open(routeData.href, '_blank');
				});
			}
			
			if(this.editable)
				this.initAllEvents();
		},
		watch: {
			paths(){
				this.polygon.setPath(this.paths);
				this.initPathEvents();
			}
		},
		props: {
			google: {
				type: Object,
				required: true
			},
			map: {
				type: Object,
				required: true
			},
			paths: {
				type: Array,
				required: true
			},
			editable: {
				type: Boolean,
				default: false
			},
			onClickRoute: {
				type: Object,
				default: () => {}
			},
			options: {
				type: Object,
				default: function(){
					return {
						strokeColor: '#FF0000',
						strokeOpacity: 0.8,
						strokeWeight: 2,
						fillColor: '#FF0000',
						fillOpacity: 0.35,
					}
				}
			}
		},
	};
</script>
