<template>
	<div class="country-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'country-edit' ? $t("titles.editCountry") : $t("titles.createCountry")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$route.name === 'country-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="isFetchingRequiredApiData || ready !== true" />
		<template v-else>
			<div class="row pt-4">
				<div :class="$route.name === 'country-edit' ? 'col-md-4' : 'col-md-12'">
					<form-container>
						<div class="part-label col-12">{{$t("titles.countryDetails")}}</div>
						<field-input class="col-md-12" :label='$t("labels.country")' v-model="name" :error="nameError" />
						<!--<field-input class="col-md-12" :label='$t("labels.currency")' v-model="currency" :error="currencyError" />-->
						<field-input class="col-md-12" :label='$t("labels.countryCode")' v-model="countryCode" :error="countryCodeError" />
						<field-input class="col-md-12" :label='$t("labels.districts")' :value='pathDataArray.length+ " " + $tc("entities.district",pathDataArray.length)' :disabled='true' />
						<field-textarea class="col-md-12" :label='$t("labels.bankInfo")' v-model="bankInfo" />
						<div class="col-12">
							<div v-if="responseError !== ''" class="error w-mb-20">{{responseError}}</div>
						</div>
					</form-container>
				</div>
				<div class="col-md-8">
					<div class="py-4">
						<google-map v-if="$route.name === 'country-edit'"
								containerStyles="width: 100%; height: 100%; position: absolute;"
								:map-config='{
									zoom: 8,
									center: {lat: 0, lng: 0},
									zoomControl: true,
									mapTypeControl: false,
									scaleControl: false,
									streetViewControl: false,
									rotateControl: false,
									fullscreenControl: false,

									options: {
										//gestureHandling: "none" //
									},
								}'
								:bounds='bounds'
								:libraries="['drawing']">
							<template  slot-scope="{ google, map }">
                    			<map-polygon v-for='(pathGroup, index) in pathDataArray'
									:key='index'
									:google="google"
									:map="map"
									:paths="pathGroup.coords"
									:options="pathGroup.options"
									:onClickRoute="pathGroup.route"
									/>
							</template>
						</google-map>
					</div>
				</div>
			</div>
			<entity-booking-rules-form v-model="ruleset.booking" :errors="bookingRuleErrors" :default-values="routeCountry.defaultRuleset" />
			<entity-currency-rules-form v-model="ruleset.currency" :default-values="routeCountry.defaultRuleset" />
		</template>
	</div>
</template>

<script>
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import isGranted from "@/mixin/isGranted";
	import requiresApiRequest from "@/mixin/requiresApiRequest";
	import {normalizeRuleValue} from "@/helperFunctions";
	import rules from "@/rules";
	import EntityCurrencyRulesForm from "@/components/forms/EntityCurrencyRulesForm";
	import EntityBookingRulesForm from "@/components/forms/EntityBookingRulesForm";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SelectInput from "@/components/inputs/SelectInput";
	import SmallButton from "@/components/buttons/SmallButton";
	import GoogleMap from '@/components/maps/GoogleMap.vue';
	import MapMarker from '@/components/maps/MapMarker.vue';
	import MapPolygon from '@/components/maps/MapPolygon.vue';
	import {hashCode} from '@/helperFunctions';
	import permissions from "@/permissions";
	import FieldTextarea from "@/components/inputs/FieldTextarea";

	export default {
		name: "AddEditCountry",
		components: {Spinner, StandardButton, RegularInput, FormContainer, FieldInput, SelectInput, SmallButton,EntityBookingRulesForm,EntityCurrencyRulesForm, GoogleMap, MapMarker, MapPolygon,FieldTextarea},
		mixins: [isGranted,requiresApiRequest],
		data(){
			return {
				ready: true,
				name: '',
				nameError: '',
				countryCode: '',
				countryCodeError: '',
				currency: '',
				currencyError: '',
				responseError: '',
				bankInfo: '',
				ruleset: {
					currency: {},
					booking: {}
				},
				bookingRuleErrors: {},
				loader: false,

				// When we are able to get paths from child districts add them to pathDataArray
				pathDataArray: [
					/*{
						coords:[
							{lat: 10, lng: 10},
							{lat: 10, lng: 20},
							{lat: 20, lng: 20}
						],
						options: {
							strokeColor: '#00FF00',
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: '#00FF00',
							fillOpacity: 0.35,
						},
						url: "https://google.com"
					},
					{
						coords:[
							{lat: 50, lng: 50},
							{lat: 50, lng: 60},
							{lat: 60, lng: 60}
						],
						options: {
							strokeColor: '#0000FF',
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: '#0000FF',
							fillOpacity: 0.35,
						},
						url: "https://facebook.com"
					},
					{
						coords:[
							{lat: 0, lng: 50},
							{lat: 50, lng: 0},
							{lat: 0, lng: 250}
						],
						options: {
							strokeColor: '#FF0000',
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: '#FF0000',
							fillOpacity: 0.35,
						},
						url: "https://twitter.com"
					}*/
				],
			}
		},
		computed: {
			...mapState('concrete', {
				countries: 'countries'
			}),
			...mapGetters('concrete',{
				possibleRules: 'possibleRules'
			}),
			routeCountry(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.countries[this.$route.params.id] !== 'undefined') ? this.countries[this.$route.params.id] : false ;
			},
			bounds(){
				const pointsInPaths = [];
				this.pathDataArray.forEach(function(pathGroup){
					pathGroup.coords.forEach(function(coords){
						pointsInPaths.push({lat: coords.lat, lng: coords.lng});
					});
				});
				return pointsInPaths;
			},
			mayUpdateRuleset(){
				return (typeof this.$route.params.id !== 'undefined' && this.isGranted(permissions.RULES.UPDATE,this.routeCountry['@id']))
			},
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'Country'
					},
					method: (this.$route.name === 'country-edit') ? 'put' : 'post',
					payload: {name:this.name, countryCode:this.countryCode}
				};
				if(this.$route.name === 'country-edit')
					payload.entity.id = this.$route.params.id;

				if(this.mayUpdateRuleset)
					payload.payload.ruleset = this.buildRulesetRequest();

				if(this.hasGlobalSuperAdmin)
					payload.payload.bankInfo = this.bankInfo

				return payload;
			},
			attemptSubmit(){
				if(this.loader) return;
				
				this.validateInputs();
				if(this.nameError === '' && this.countryCodeError === '' && Object.keys(this.bookingRuleErrors).length === 0)
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							if(this.$route.name === 'country-add')
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.countryWasCreatedTitle"),
									text: this.$t("alerts.countryWasCreatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((result) => {
									if (result.isConfirmed) {
										this.$router.push({ name: 'entity-list', params: {entityType: 'countries',lang: this.$i18n.locale} })
									}
								});
							}
							else
							{
								this.$swal.fire({
									icon: 'success',
									title: this.$t("alerts.countryWasUpdatedTitle"),
									text: this.$t("alerts.countryWasUpdatedDescription"),
									confirmButtonText: this.$t("terms.ok"),
									confirmButtonColor: "#495760"
								}).then((response) => {
									if (response.isConfirmed) {
										this.$router.push({ name: 'country-view', params: {id: result.id,lang: this.$i18n.locale} })
									}
								});
							}
							
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined' && typeof error.data.violations !== 'undefined')
						{
							error.data['violations'].forEach((error) => {
								console.log(error.propertyPath);
								if(typeof this[error.propertyPath] !== 'undefined')
								{
									this[error.propertyPath+'Error'] = this.$t('symfonyErrors.'+error.message.replace(/\./g, ""));
								}
							});
						}
						
						if(typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined')
						{
							// @todo Handle specific errors
							this.responseError = 'An error occurred. Please try again.';
						}
						else
						{
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				this.nameError = '';
				this.countryCodeError = '';
				this.currencyError = '';
				this.bookingRuleErrors = {};
				if(this.name === '')
					this.nameError = this.$t("validation.pleaseProvideCountryName");
				
				if(this.countryCode === '')
					this.countryCodeError = this.$t("validation.pleaseProvideCountryCode");
				if(this.currency === '')
					this.currencyError = this.$t("validation.pleaseProvideCurrency");

				if(this.mayUpdateRuleset)
				{
					this.determineRulesetErrors();
				}
				console.log(this.bookingRuleErrors);
			},
			determineRulesetErrors(){
				if(typeof this.ruleset.booking[rules["BOOKING:SLOTS"].key] !== 'undefined')
				{
					this.ruleset.booking[rules["BOOKING:SLOTS"].key].forEach((single, index) =>
					{
						Object.keys(single).forEach((property) =>
						{
							if (this.ruleset.booking[rules["BOOKING:SLOTS"].key][index][property] === null || this.ruleset.booking[rules["BOOKING:SLOTS"].key][index][property] === '')
							{
								if (typeof this.bookingRuleErrors.bookingslot === 'undefined')
								{
									this.bookingRuleErrors.bookingslot = {};
								}
								this.bookingRuleErrors.bookingslot[property + '-' + index] = this.$t('bookingRulesForm.repeaterError');
							}
						});
					})
				}

				if(typeof this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key] !== 'undefined')
				{
					this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key].forEach((single,index) => {
						Object.keys(single).forEach((property) => {
							if(this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key][index][property] === null || this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key][index][property] === '')
							{
								if(typeof this.bookingRuleErrors.lateCancel === 'undefined')
								{
									this.bookingRuleErrors.lateCancel = {};
								}
								this.bookingRuleErrors.lateCancel[property+'-'+index] = this.$t('bookingRulesForm.repeaterError');
							}
						});
					})
				}
			},
			updateLocalValues(){
				this.name = this.routeCountry.name;
				this.countryCode = this.routeCountry.countryCode;
				this.ruleset = {
					currency: {},
					booking: {}
				};
				if(typeof this.routeCountry.ruleset !== 'undefined')
				{
					this.possibleRules.forEach((rule) => {
						if(typeof this.routeCountry.ruleset[rule] !== 'undefined' && normalizeRuleValue(rule,this.routeCountry.ruleset[rule]) !== normalizeRuleValue(rule,this.routeCountry.defaultRuleset[rule]))
						{
							this.ruleset[rules[rule].group][rule] = this.routeCountry.ruleset[rule];
						}
					})
				}

				this.routeCountry.districts.forEach((district) => {
					let districtPathObject = {
						coords: JSON.parse(district.bounds),
						options: {
							strokeColor: hashCode(this.routeCountry.name+district.id*500),
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: hashCode(this.routeCountry.name+district.id*500),
							fillOpacity: 0.35,
						},
						route: { name: 'district-view', params: {id: district.id} }
					}

					this.pathDataArray.push(districtPathObject)
				});

				if(typeof this.routeCountry.bankInfo !== 'undefined')
					this.bankInfo = this.routeCountry.bankInfo;

				this.ready = true;
			},
			buildRulesetRequest()
			{
				let ruleset = {};
				Object.keys(this.ruleset).forEach((set) => {
					const setKeys = Object.keys(this.ruleset[set]);
					if(setKeys.length !== 0)
					{
						setKeys.forEach((rule) => {
							ruleset[rule] = this.ruleset[set][rule];
						});
					}
				});

				return ruleset;
			},
			fetchRequired(){
				this.ready = false;
				let promises = [];
				if(typeof this.$route.params.id !== 'undefined')
				{
					promises.push(this.addRequiredApiRequest({type: 'generic',payload: {parameters: {explode:'district:bounds'},entity: {'id': this.$route.params.id, '@type': 'Country'}}}))
				}
				
				if(promises.length > 0)
				{
					Promise.all(promises)
					.then(response =>
					{
						this.updateLocalValues();
					})
					.catch(error =>
					{
						console.log(error);
						this.ready = true;
					});
				}
				else
				{
					this.ready = true;
				}
			}
		},
		created()
		{
			this.fetchRequired();
			
		},
		watch:{
			$route(){
				this.fetchRequired();
			}
		}
	};
</script>

<style scoped>

</style>
